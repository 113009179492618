body#page-layout-faculty-profile #social-icon-bar {
    display: none;
}
/* wrapper - grid - container - adjustments */
body#page-layout-faculty-profile #main-content-wrapper {
    grid-gap: 0;
    grid-row-gap: 2rem;
    @include phone-portrait {
        grid-auto-rows: auto;
        grid-gap: 0;
        grid-row-gap: 2rem;
        margin: 0;
        padding: 2rem;
        width: 100%;
    }
}
body#page-layout-faculty-profile #main-content-wrapper #main-section-wrapper {
    grid-column: 1 / span 8;
    @include phone-portrait {
        margin: 0;
        grid-column: 1 / 1 !important;
        grid-row: 2 / span 1 !important;
    }
}
body#page-layout-faculty-profile #main-content-wrapper #main-section-wrapper .padding-sm {
    @include phone-portrait {
        padding: .5rem;
    }
}
body#page-layout-faculty-profile #main-content-wrapper #main-aside-wrapper-navigation {
    grid-column: 9 / span 4;
    @include phone-portrait {
        grid-column: 1 / span 1 !important;
        grid-row: 1 / span 1;
    }
}
body#page-layout-faculty-profile #main-content-wrapper #main-aside-wrapper-content {
    grid-column: 9 / span 4;
    @include phone-portrait {
        grid-column: 1 / span 1 !important;
        grid-row: 3 / span 1;
    }
}
body#page-layout-faculty-profile #main-content-wrapper #main-section-wrapper,
body#page-layout-faculty-profile #main-content-wrapper #main-aside-wrapper-navigation .container,
body#page-layout-faculty-profile #main-content-wrapper #main-aside-wrapper-content .container {
    margin: 0 5%;
    width: 90%;
    @include phone-portrait {
        margin: 0 5%;
        width: 90%;
    }
}
/* card styling */
body#page-layout-faculty-profile #main-aside-wrapper-navigation .profile-cards .image-wrapper {
    margin-bottom: -.25rem;
}
body#page-layout-faculty-profile #main-aside-wrapper-navigation .profile-cards .content-grid-wrapper {
    display: block;
}
body#page-layout-faculty-profile #main-aside-wrapper-navigation .profile-cards .flex-item.content h4 {
    margin-top: 0;
    margin-bottom: calc(var(--baseline)* 0.25* 1rem);
}
body#page-layout-faculty-profile #main-aside-wrapper-navigation .profile-cards h5 {
    padding-bottom: calc(var(--baseline)* 0.75* 1rem);
    margin-top: 0 !important;
    margin-bottom: calc(var(--baseline)* 0.75* 1rem);
    font-size: 1.25rem;
    text-transform: none;
    border-bottom: solid 1px $black;
}
body#page-layout-faculty-profile #main-aside-wrapper-navigation .profile-cards p,
body#page-layout-faculty-profile #main-aside-wrapper-navigation .profile-cards ul li {
    margin: 0 0 .35rem !important;
    font-size: .95rem;
    line-height: 1.35 !important;
}
body#page-layout-faculty-profile #main-aside-wrapper-navigation .profile-cards ul {
    margin: 0 0 1rem 2.5rem;
}
body#page-layout-faculty-profile #main-aside-wrapper-navigation .profile-cards ul li {
    text-indent: -1.35rem;
    a {
        font-weight: 600;
    }
}
body#page-layout-faculty-profile #main-aside-wrapper-navigation .profile-cards #social-icon-bar {
    position: relative;
    left: 0;
    display: inline;
    margin: 0;
    opacity: 1 !important;
    article {
        margin: 1.25rem 0 0 0;
    }
}
body#page-layout-faculty-profile #main-aside-wrapper-navigation .profile-cards #social-icon-bar a {
    display: inline;
    margin: 1rem .125rem 0 !important;
    padding: 0 !important;
    background: none !important;
    border-radius: 0 !important;
    border: 0 none !important;
}
body#page-layout-faculty-profile #main-aside-wrapper-navigation .profile-cards #social-icon-bar a i {
    margin: 0;
    font-size: 1.5rem;
    line-height: 0;
    color: $maroon;
    cursor: pointer;
}

