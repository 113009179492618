/* MOBILE-NAV-BAR - SLIDING SIDE NAV */

/* mobile-nav-bar */

nav#main-mobile {
  display: none;
  float: right;
  margin: 0;
  padding: 0;
  width: 40px;
  background: none;
  @include tablet-portrait {
    display: inline-block;
  }
}

#main-mobile .container-fluid .container {
  @include tablet-portrait {
    padding: 0 15px;
    width: 100%;
  }
}

nav#main-mobile .menu-bar {
  display: block;
  margin: 6px 0;
  width:35px;
  height: 5px;
	background: $gray-light;
}

/* mobile - menu-button */

#menu-button-mobile {
  position: relative;
  margin: 0;
  padding: 0;
  width: 40px;
  height: 45px;
  border: none !important;
  background: none;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out opacity;
  -moz-transition: .5s ease-in-out opacity;
  -o-transition: .5s ease-in-out opacity;
  transition: .5s ease-in-out opacity;
  outline: 0;
  opacity: .8;
  cursor: pointer;
  &:hover {
    background: none;
    opacity: 1;
  }
}

#menu-button-mobile:hover span.menu-bar-one,
#menu-button-mobile:hover span.menu-bar-two,
#menu-button-mobile:hover span.menu-bar-three,
#menu-button-mobile:hover span.menu-bar-four {
  background-color: $gray-light;
}

#menu-button-mobile:hover span.menu-text {
  color: $gray-light;
}

#menu-button-mobile span.menu-bar-one,
#menu-button-mobile span.menu-bar-two,
#menu-button-mobile span.menu-bar-three,
#menu-button-mobile span.menu-bar-four {
  display: block;
  position: absolute;
  margin: 0;
  height: 5px;
  width: 100%;
  background-color: $gray-light;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

/* menu-bar spans */

#menu-button-mobile span.menu-bar-one {
  top: 0px;
}

#menu-button-mobile span.menu-bar-two, #menu-button-mobile span.menu-bar-three {
  top: 10px;
}

#menu-button-mobile span.menu-bar-four {
  top: 20px;
}

#menu-button-mobile.open span.menu-bar-one {
  top: 9px;
  width: 0%;
  left: 50%;
}

#menu-button-mobile.open span.menu-bar-two {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#menu-button-mobile.open span.menu-bar-three {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#menu-button-mobile.open span.menu-bar-four {
  top: 10px;
  width: 0%;
  left: 50%;
}

#menu-button-mobile > span.menu-text {
  color: $gray-light;
}

span.menu-text, span.search-text {
  display: block;
  margin-top: 0.5em;
  font-size: .9em;
  text-transform: uppercase;
  text-align: center;
}

span.menu-text {
  position: absolute;
  bottom: -4px;
  width: 100%;
  font-size: .725em;
}

.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}

#side-nav {
	position: fixed;
  top: 0;
  left: -70vw;
	width: 70vw;
  max-width: 70vw;
  height: auto;
  min-height: 100vh;
  overflow: auto;
	background-color: $white;
  overflow-x: hidden; /* Disable horizontal scroll */
	z-index: 10002;
  -moz-transition: all 300ms;
  -o-transition: all 300ms;
  -webkit-transition: all 300ms;
  transition: all 300ms;
  @include tablet-portrait {
    left: -85vw;
    width: 85vw;
    max-width: 85vw;
  }
  @include phone-portrait {
    left: -70vw;
    width: 70vw;
    max-width: 70vw;
  }
}

/* MOVE BODY
body.active {
    -moz-transform: translateX(-220px);
    -ms-transform: translateX(-220px);
    -webkit-transform: translateX(-220px);
    transform: translateX(-220px);
  }
*/

body.active {
  height: 50% !important;
  min-height: 50% !important;
  overflow: hidden !important;
}

body.active #main-content-start {
  overflow: hidden;
}

body.active #side-nav {
  display: none;
  @include tablet-portrait {
    display: block;
    -moz-transform: translateX(85vw);
    -ms-transform: translateX(85vw);
    -webkit-transform: translateX(85vw);
    transform: translateX(85vw);
    height: 100vh;
    overflow: scroll;
  }
  @include phone-portrait {
    -moz-transform: translateX(70vw);
    -ms-transform: translateX(70vw);
    -webkit-transform: translateX(70vw);
    transform: translateX(70vw);
  }
}

#sidenav-inner-wrapper {
	padding: 2rem 2rem 150px 2rem; /* V1 menu */
  padding: 2rem; /* V2 layered menu */
}

#global-ctas {
  padding: 2rem 0 0;
}

#global-ctas > button {
  width: 100%;
  &:hover {
    color: darken($brick,5%);
    outline-style: solid;
    outline-offset: -3px;
    outline: 3px solid $gold;
  }
}

/* The navigation menu links */
#side-nav a,
#side-nav h4.minimalist-accordion__title {
	display: block;
  padding: .75rem;
  text-decoration: none;
	font-family: 'Antenna', sans-serif;
  font-size: .95rem !important;
  color: $gray-light;
	text-transform: uppercase;
	border-bottom: solid 1px $gray-dark;
  transition: 0.3s;
  button {
    padding: .75rem;
    font-size: .95rem !important;
    color: $gray-light !important;
    border-bottom: none !important;
    width: 100%;
  }
  &:focus {
    outline-style: solid;
    outline-offset: -3px;
    outline: 3px solid #febc18;
  }
  &:hover {
    background: rgba(128, 128, 128, 0.3);
    background: darken($gray-extra-dark,6%) !important;
  }
}

#side-nav button.minimalist-accordion__header:hover,
#side-nav button.minimalist-noanim-accordion__header:hover,
#side-nav button.minimalist-css-accordion__header:hover,
#side-nav button.minimalist-accordion__header:focus,
#side-nav button.minimalist-noanim-accordion__header:focus,
#side-nav button.minimalist-css-accordion__header:focus {
  background: darken($gray-extra-dark,3%) !important;
}

#side-nav h4.minimalist-accordion__title {
  padding: 0;
  :after {
    position: relative;
    top: .25rem;
    right: 1.5rem;
    width: 0.5rem;
    height: 0.5rem;
    content: ">";
    line-height: 0;
    font-size: 1rem;
    font-family: 'Antenna Bold';
    color: $gray-light !important;
    transform-origin: center center;
    transform: rotate(90deg);
    background-image: none !important;
  }
}

#side-nav [aria-expanded="true"].minimalist-accordion__header::after {
  top: .25rem;
  right: 1.85rem;
}

#side-nav .minimalist-accordion__panel {
  margin: 0;
  padding: 0;
  font-size: 1rem !important;
  a {
    text-transform: none;
    background: darken($gray-extra-dark,3%);
    &:hover {
      background: darken($gray-extra-dark,9%) !important;
    }
  }
}

#side-nav p {
	text-transform: none;
	background: none;
	color: $gray-light;
}

/* Position and style the close button (top right corner) */
#side-nav-close {
  position: relative;
  margin: 0;
  width: 36px;
	height: auto;
	border-bottom: none;
	opacity: .5;
}

/* On smaller screens, where height is less than 450px,
change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
  #side-nav {padding-top: 15px;}
  #side-nav a {font-size: 1.5em;}
}

/* side nav - footer address */
#side-nav #footer-address {
	display: block;
	margin: 20px 0;
	font-family: 'Arial', sans-serif;
	font-size: .95em;
	color: $gray;
}

#side-nav #footer-address span {
	display: block;
	margin: 0 0 8px 0;
	width: 100%;
}

#side-nav #footer-address span#fnorg {
	color: $gray-light;
	text-transform: uppercase;
}

#side-nav #footer-address span#locality,
#side-nav #footer-address span.region,
#side-nav #footer-address span.postal-code {
	display: inline;
	width: auto;
}

#side-nav #footer-address a.email {
	margin: 20px 0;
	padding: 5px 10px;
	height: 30px;
	font-size: .85em;
	text-align: center;
	color: $gray-light;
	border: solid 1px $gray;
	border-radius: 5px;
	background: rgba(255,255,255,.15);
	transition: all .2;
	&:hover {
		background: rgba(255,255,255,.5);
	}
}

.copyright p {
	font-size: 1em;
  color: $gray-light;
}

#side-nav-close img {
	width: 70%;
}

/* side nav - search */

#side-nav-search-wrapper {
	margin: 10px 0 20px;
	padding: 0;
}

#side-nav-search-wrapper form {
	margin: 0;
	padding: 0;
	width: 100%;
	background: none;
}

#side-nav-search-wrapper form input {
  margin: 0;
	padding: 20px;
	height: 40px;
	width: 85%;
	background: $gray-light;
	border: none;
}

#side-nav-search-wrapper form input {
	font-size: 1.15em;
	text-transform: uppercase;
  border-radius: .33rem 0 0 .33rem;
  ::placeholder {
    color: red !important;
  }
}

#side-nav form input::placeholder {
  color: $gray-dark;
}

#side-nav-search-wrapper form button {
  float: right;
  position: relative;
	box-sizing: border-box;
	margin: 0;
	padding: 8px 12px;
  width: 15%;
	height: 40px;
  font-size: 1.15em;
	outline: 0 none;
	border-radius: 0 .33rem .33rem 0;
	background-color: $maroon;
	cursor: pointer;
	transition: all .2s;
		&:hover {
			color: darken($gray-light, 10%);
			background-color: darken($maroon, 10%);
		}
}

#side-nav-search-wrapper #search-label { font-size: .015em; }
