/* nav-sliding-menu and nav secondary */
#secondary .multilevel-linkdiv-0, #side-nav .multilevel-linkdiv-0 {
margin: 0 !important;
padding: .75rem !important;
display: block !important;
max-height: 100em !important;
visibility: visible !important;
overflow: hidden !important;
opacity: 1 !important;
transition: visibility 0s ease, max-height 1s ease, opacity 1s ease !important;
text-transform: none !important;
text-decoration: none !important;
font-family: 'Antenna', sans-serif !important;
font-size: .95rem !important !important;
color: darken($gray-extra-light,3%) !important;
transition: 0.3s !important;
}

#secondary .currentbranch0 a, #secondary .multilevel-linkdiv-0 .currentbranch0 a, #secondary .multilevel-linkdiv-0 .currentbranch1 a {
color: $gray-extra-light !important;
background: none !important;
}
