/* SECONDARY NAV */

nav#secondary {
	float: none;
	margin-right: 2rem;
	padding: 2em;
	border-radius: .75rem 0 0 .75rem;
	background: darken($gray-extra-light, 5%) !important;
	a, h4 {
		display: block;
		margin: 0;
		padding: 1.25rem .5rem 1rem;
		width: 100%;
		font-family: 'Antenna', sans-serif;
		font-size: .725rem;
		line-height: 1.45;
		color: $black;
		text-indent: 0 !important;
		text-transform: uppercase;
		text-decoration: none;
		border-bottom: solid 1px $gray-light;
		transition: .2s;
		&:hover {
			background: darken($gray-extra-light, 15%) !important;
		}
		&:focus {
			outline-style: solid;
	    outline-offset: -3px;
	    outline: 3px solid $gold;
		}
	}
	h4 {
		padding: 0;
		border: none;
		button.minimalist-accordion__header,
		button.minimalist-noanim-accordion__header,
		button.minimalist-css-accordion__header, {
			padding: 1.125rem .5rem 1rem;
			font-size: .75rem;
			text-transform: uppercase;
			border: none;
			border-bottom-color: $gray-light !important;
			&:hover {
				color: $black !important;
				background: darken($gray-extra-light, 15%) !important;
			}
		}
	}
	a {
		.minimalist-accordion__header,
		.minimalist-noanim-accordion__header,
		.minimalist-css-accordion__header, {
			padding: 1.125rem .5rem 1rem;
			font-size: .85rem;
			border: none;
			&:hover {
				color: $black !important;
				background: darken($gray-extra-light,15%) !important;
			}
		}
	}
}

nav#secondary .currentbranch0,
nav#secondary .currentbranch4 {
	background: $maroon;
	button.minimalist-accordion__header,
	button.minimalist-noanim-accordion__header,
	button.minimalist-css-accordion__header {
	color: $gray-extra-light !important;
		&:hover {
			color: $gray-extra-light !important;
			background: $brick !important;
		}
		&:after {
			background-image: url(../images/ui-arrow-carrousel-next@2x.png);
		}
	}
}

nav#secondary .minimalist-accordion__header::after,
nav#secondary .minimalist-noanim-accordion__header::after,
nav#secondary .minimalist-css-accordion__header::after {
	margin: .075rem;
}

nav#secondary .minimalist-accordion__panel {
	margin: 0;
	background: $gray-light;
	a, h4 {
		&:hover {
			background: darken($gray-extra-light, 15%) !important;
		}
	}
}

/* update */
nav#secondary a {
	&:hover {
		color: $maroon !important;
	}
	button {
		&:hover {
			color: $maroon !important;
		}
	}
}
nav#secondary a {
	button {
		padding: 0;
		margin: 0;
		font-family: 'Antenna', sans-serif;
		font-size: .85rem;
		text-align: left;
		color: $black !important;
		border: 0 !important;
		background: none;
		&:hover {
			color: $maroon;
			background: none;
			outline: none;
		}
		&:focus {
			outline: none;
		}
		::after {
			margin-top: -2rem;
		}
	}
}
nav#secondary .minimalist-noanim-accordion__header::after,
nav#secondary .minimalist-css-accordion__header::after {
	margin: 0.1rem 0 0 0 !important;
	transform: rotate(0deg);
}
nav#secondary .minimalist-noanim-accordion__header::after,
nav#secondary .minimalist-css-accordion__header::after {
	margin: 0.1rem 0 0 0 !important;
	transform: rotate(90deg) !important;
	background-image: none;
}
nav#secondary .minimalist-accordion__panel {
	margin: 0 !important;
	background: darken($gray-extra-light,10%) !important;
	a {
		color: $black !important;
	}
}
// nav#secondary .minimalist-accordion__header,
// nav#secondary .minimalist-noanim-accordion__header,
// nav#secondary .minimalist-css-accordion__header {
// 	&[aria-expanded="true"] {
//         &:before,
//         &:after {
// 		transform: rotate(90deg) !important;
// }
// }
// }
/* CSS - END */
